


























































import Vue from 'vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

interface IPixelOption {
  label: string
  code: string
}

interface IEventOption {
  label: string
  code: string
}

export default Vue.extend({
  name: 'Conversion',
  components: {
    VSelect
  },
  props: {
    campaignId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('adsManager/adDesign', [
      'adDesignData'
    ]),
    ...mapGetters('adsManager/adDesign', [
      'getPixelList',
      'getConversionEvents',
      'getError'
    ]),
    getPixelListAsOptions () {
      if (this.getPixelList === undefined) {
        return []
      }
      return this.getPixelList.map(pixel => {
        return {
          label: pixel.name,
          code: pixel.id
        }
      })
    },
    getConversionEventsAsOptions () {
      if (this.getConversionEvents === undefined) {
        return []
      }
      return this.getConversionEvents.map(event => {
        return {
          label: event.title,
          code: event.value
        }
      })
    },
    pixel_selected: {
      get: function (): IPixelOption | undefined {
        if (!this.adDesignData) {
          return undefined
        } else {
          return this.getPixelListAsOptions.find(pixel => pixel.code === this.adDesignData.pixel_id)
        }
      },
      set: function (pixel: IPixelOption) {
        this.removeError({ errorField: 'pixel_id' })
        this.setStateFieldProp({
          field: 'adDesignData',
          prop: 'pixel_id',
          value: pixel.code
        })
      }
    },
    event_selected: {
      get: function (): IEventOption | undefined {
        if (!this.adDesignData) {
          return undefined
        } else {
          return this.getConversionEventsAsOptions.find(event => event.code === this.adDesignData.conversion_event)
        }
      },
      set: function (event: IEventOption) {
        this.removeError({ errorField: 'conversion_event' })
        this.setStateFieldProp({
          field: 'adDesignData',
          prop: 'conversion_event',
          value: event.code
        })
      }
    }
  },
  methods: {
    ...mapActions('adsManager/adDesign', [
      'get_conversion_pixel',
      'get_conversion_events'
    ]),
    ...mapMutations('adsManager/adDesign', ['setStateFieldProp', 'removeError'])
  },
  created () {
    this.get_conversion_pixel(this.campaignId)
    this.get_conversion_events(this.campaignId)
  }
})
