













































































import Vue from 'vue'
import Normal from './Normal.vue'
import PlacementsNew from './PlacementsNew.vue'
import MediaModal from './MediaModal.vue'
import NewCampaignLayout from '@/components/ADSManager/NewCampaignLayout.vue'
import InstantForm from '@/components/ADSManager/NewCampaign/AdDesign/InstantForm.vue'
import AdsNameTemplate from '../../../components/ADSManager/NewCampaign/AdDesign/AdsNameTemplate.vue'
import FacebookPage from '../../../components/ADSManager/NewCampaign/AdDesign/FacebookPage.vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import Conversion from './Conversion.vue'
import Media from './AdDesign/Media.vue'
import Carousel from '@/components/ADSManager/NewCampaign/Carousel/Carousel.vue'

export default Vue.extend({
  name: 'AdDesign',
  components: {
    MediaModal,
    Normal,
    PlacementsNew,
    InstantForm,
    AdsNameTemplate,
    FacebookPage,
    NewCampaignLayout,
    Conversion,
    Media,
    Carousel
  },
  data () {
    return {
      showPages: false,
      tabGallery: 1,
      val: '',
      selected_page: null,
      open: false,
      text: null,
      display_link: null,
      type: 'Custom',
      destination: null,
      aim: /* 'traffic' */ 'lead_generation',
      fb_event: null,
      country_code: null,
      phone: null,
      activeItem: 1,
      clicks: 0,
      ad_sets: 0,
      testing: [],
      carousel: [
        {
          id: 0,
          image: null,
          state: 'included',
          active: false,
          url: { url: '' },
          headline: { headline: '' },
          ad_text: { ad_text: '' },
          call_to_actions: {}
        },
        {
          id: 1,
          image: null,
          state: 'included',
          active: false,
          url: { url: '' },
          headline: { headline: '' },
          ad_text: { ad_text: '' },
          call_to_actions: {}
        }
      ],
      selected_media: [
        {
          src: require('@/assets/ads-manager/burger_example/burger-ex-1.svg'),
          id: 1
        }
      ]
    }
  },
  computed: {
    ...mapState('adsManager/adDesign', [
      'adDesignServerData',
      'adDesignData',
      'media',
      'lead_method',
      'errors',
      'brand_awareness_optional_fields_toggle',
      'headline',
      'targetLink',
      'placements_saving_type',
      'displayLink'
    ]),
    ...mapState('adsManager', [
      'campaign'
    ]),
    ...mapGetters('adsManager/adDesign', [
      'getError',
      'getInstantFormByFbId',
      'getCallToActionSettings'
    ]),
    type_of_media: {
      get () {
        return this.adDesignData.creative_format
      },
      set (v) {
        this.setStateFieldProp({
          field: 'adDesignData',
          prop: 'creative_format',
          value: v
        })
      }
    },
    imageBaseUrl () {
      return process.env.VUE_APP_BASE_URL
    },
    next_step_status () {
      return this.placements_saving_type === 'custom' && (this.adDesignData.new_ad_placement.platforms.length === 0 || this.adDesignData.new_ad_placement.positions.length === 0)
    },
    carouselErrors (): Array<{ id: number, field: string, detail: string }> {
      return this.errors.map(({ field, detail }) => {
        const split = field.split('.')
        return {
          id: Number(split[1]),
          field: split[2],
          detail
        }
      })
    }
  },
  mounted () {
    localStorage.setItem('process_creating_instant_form', JSON.stringify({ campaignId: this.$route.params.campaign_id, status: 'end' }))
    this.$emit('input', this.selected)
    this.load_campaign({ campaignId: this.$route.params.campaign_id, params: {} })
    this.load_campaign_targeting(this.$route.params.campaign_id)
    this.get_ad_set_info(this.$route.params.campaign_id)
      .then((response) => {
        this.get_pages({ campaignId: this.$route.params.campaign_id })
          .then((res) => {
            this.get_instagram_accounts({ campaignId: this.$route.params.campaign_id, pageId: res.data.data[0].attributes.fb_id })
              .then(() => {
                this.showPages = true
              })
          })
      })
    this.get_media()
  },
  methods: {
    ...mapActions('adsManager/adDesign', ['get_ad_set_info', 'get_pages', 'get_instagram_accounts', 'get_media', 'save_ad_design', 'setPlacements', 'deleteImageItemFromAdDesignData']),
    ...mapActions('adsManager', ['load_campaign', 'load_campaign_targeting']),
    ...mapMutations('adsManager/adDesign', ['setStateField', 'setStateFieldProp', 'adImageInAttachment', 'addError', 'removeError', 'changeChildAttachment', 'resetChildAttachments', 'deleteChildAttachment', 'overridingId', 'activateChildAttachment']),
    ...mapActions('notifications', ['addNotification']),
    adsNameTemplateChange (value) {
      console.log(value)
      this.setStateFieldProp({
        field: 'adDesignData',
        prop: 'ad_name_template',
        value: value
      })
      this.removeError({
        errorField: 'ad_name_template'
      })
    },
    changeInstantForm (acc) {
      this.setStateFieldProp({
        field: 'adDesignData',
        prop: 'lead_gen_form_ids',
        value: [acc]
      })
      this.removeError({
        errorField: 'lead_gen_form_ids'
      })
    },
    checkAdDesignClientSide () {
      if (this.brand_awareness_optional_fields_toggle &&
        this.adDesignData.creative_format === 'normal') {
        if (!this.adDesignData.headline.length && !this.headline.length) {
          this.addError({
            field: 'headline',
            detail: 'The headline field is required when creative format is images.'
          })
        }
        if (!this.adDesignData.link_url.length) {
          if (this.targetLink && this.targetLink.length) {
            this.setStateFieldProp({
              field: 'adDesignData',
              prop: 'link_url',
              value: [{
                link_view: this.displayLink || '',
                url: this.targetLink
              }]
            })
          } else {
            this.addError({
              field: 'link_url',
              detail: 'The link url field is required when media is images.'
            })
          }
        }
      }
    },
    saveAdDesign () {
      this.checkAdDesignClientSide()
      if (this.errors.length === 0) {
        this.save_ad_design(this.$route.params.campaign_id)
          .then(() => {
            this.$router.push({ name: 'NewCampaignBudget' })
          })
      } else {
        for (const e of this.errors) {
          this.addNotification({
            body: e.detail,
            type: 'danger'
          })
        }
      }
    },
    setLeadMethod (acc) {
      this.setStateField({ field: 'lead_method', value: acc })
    },
    setCarouselMediaType () {
      this.type_of_media = 'carousel'
      if (this.adDesignData.child_attachments.length === 0) {
        this.setStateFieldProp({
          field: 'adDesignData',
          prop: 'child_attachments',
          value: this.carousel
        })
      }
      this.setPlacements({
        creativeFormat: 'carousel',
        placements: this.adDesignServerData.placements
      })
    },
    replaceSpace (arg: string) {
      return arg.toLowerCase().split(' ').join('_')
    },
    change_type (data) {
      this.type = data
    },
    setImageItem (selected) {
      // Заменяем массив addesignData
      const selectedImages = []
      const selectedMedia = []
      selected.forEach(item => {
        selectedImages.push(item.attributes.md5_hash)
        selectedMedia.push({ media_id: item.id })
      })
      this.setStateFieldProp({
        field: 'adDesignData',
        prop: 'images',
        value: selectedImages
      })
      this.setStateFieldProp({
        field: 'adDesignData',
        prop: ['data', 'media'],
        value: selectedMedia
      })
      this.$modal.hide('media-modal')
    },
    addImageItemCarousel (selected) {
      this.adImageInAttachment({
        id: this.adDesignData.child_attachments.find(v => v.active).id,
        image: {
          hash: selected[0].attributes.md5_hash,
          user_id: selected[0].attributes.user_id,
          format: selected[0].attributes.format,
          link: selected[0].attributes.link
        }
      })
      this.$modal.hide('media-modal')
      this.removeError({
        errorField: 'images'
      })
    },
    deleteImageItem (mediaId) {
      // удаляем из массива в adDesignData
      this.deleteImageItemFromAdDesignData(mediaId)
      this.removeError({
        errorField: 'images'
      })
    },
    showMediaModal (arg, activeSlideId = null) {
      this.tabGallery = arg
      this.$modal.show('media-modal')
      this.removeError({
        errorField: 'images'
      })
      if (activeSlideId !== null) {
        this.removeError({
          errorField: `child_attachments.${activeSlideId}.image.hash`
        })
      }
    },
    changeCarouselHeadline (event) {
      this.changeChildAttachment({
        id: event.activeSlideId,
        prop: 'headline',
        value: {
          headline: event.value
        }
      })
      this.removeError({
        errorField: `child_attachments.${event.activeSlideId}.headline.headline`
      })
    },
    changeCarouselDescription (event) {
      this.changeChildAttachment({
        id: event.activeSlideId,
        prop: 'ad_text',
        value: {
          ad_text: event.value
        }
      })
    },
    changeCarouselLink (event) {
      this.changeChildAttachment({
        id: event.activeSlideId,
        prop: 'url',
        value: {
          url: event.value
        }
      })
      this.removeError({
        errorField: `child_attachments.${event.activeSlideId}.url.url`
      })
    },
    changeCarouselCallToAction (event) {
      this.changeChildAttachment({
        id: event.activeSlideId,
        prop: 'call_to_actions',
        value: event.value
      })
    },
    changeCarouselMultiShareOptimized (event) {
      this.setStateFieldProp({
        field: 'adDesignData',
        prop: 'multi_share_optimized',
        value: event.target.checked
      })
    },
    chooseCarouselItem (item) {
      this.resetChildAttachments()
      this.activateChildAttachment({
        id: item,
        objective: this.campaign.objective
      })
      this.overridingId()
    },
    removeCarouselItem (item) {
      this.resetChildAttachments()
      this.deleteChildAttachment(item)
      this.overridingId()
    }
  }
})
