







































































































































































































































































































import Vue from 'vue'
import Card from '@/components/Card.vue'
import PrimaryText from '@/components/ADSManager/NewCampaign/AdDesign/PrimaryText.vue'
import BaseInputVuelidate from '@/components/BaseInputVuelidate.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { url } from 'vuelidate/lib/validators'
import { messages } from '../../../i18n/messages'
import Description from '@/components/ADSManager/NewCampaign/AdDesign/Description.vue'
export default Vue.extend({
  name: 'Normal',
  components: {
    Description,
    Card,
    VSelect,
    PrimaryText,
    BaseInputVuelidate
  },
  props: {
    leadMethod: {
      default: 'INSTANT_FORM'
    }
  },
  data () {
    return {
      showFields: false,
      selected: {},
      // call_to_action_arr: [],
      name: null,
      val: '',
      description: '',
      // description_arr: [],
      headline: '',
      target_link: '',
      target_link_check: false,
      display_link: '',
      display_link_check: false,
      link: {
        url: '',
        link_view: ''
      },
      display_link_arr: [],
      type: 'Custom',
      destination: 'website',
      fb_event: null,
      country_code: null,
      phone: null
    }
  },
  validations: {
    target_link: {
      url
    },
    display_link: {
      url
    }
  },
  i18n: {
    messages: messages
  },
  computed: {
    ...mapState('adsManager/adDesign', [
      'adDesignData',
      'adDesignServerData',
      'loading',
      'brand_awareness_optional_fields_toggle',
      'media',
      'variations',
      'lead_method'
    ]),
    ...mapState('adsManager', [
      'campaign'
    ]),
    ...mapGetters('adsManager/adDesign', [
      'savingDataNormalCreative',
      'getCallToActionSettings',
      'getError'
    ]),
    linksCheck () {
      return (this.$v.target_link.$model.length === 0 || this.$v.target_link.$invalid) || this.$v.display_link.$invalid
    },
    headline_arr: {
      get () {
        return this.adDesignData.headline
      },
      set (v) {
        this.setStateFieldProp({
          field: 'adDesignData',
          prop: 'headline',
          value: v
        })
      }
    },
    call_to_action_arr: {
      get () {
        return this.adDesignData.call_to_action
      },
      set (v) {
        this.setStateFieldProp({
          field: 'adDesignData',
          prop: 'call_to_action',
          value: v
        })
      }
    },
    link_arr: {
      get () {
        return this.adDesignData.link_url
      },
      set (v) {
        this.setStateFieldProp({
          field: 'adDesignData',
          prop: 'link_url',
          value: v
        })
      }
    }
  },
  watch: {
    headline_arr () {
      this.headline = ''
    },
    selected (selected) {
      this.changeCallActionState(selected)
    },
    target_link (target) {
      this.changeTargetLinkState(target)
    },
    display_link (display) {
      this.changeDisplayLinkState(display)
    },
    link_arr () {
      this.target_link = ''
      this.display_link = ''
    },
    lead_method (v) {
      if (v === 'INSTANT_FORM') {
        this.selected = {
          key: 'SIGN_UP',
          type: 'SIGN_UP',
          value: 'Sign Up'
        }
        this.removeItem(this.call_to_action_arr, {
          key: 'CALL_ME',
          type: 'CALL_ME',
          value: 'Call me'
        }, 'key')
      }
    },
    campaign (v) {
      if (v.objective === 'LEAD_GENERATION') {
        this.selected = {
          key: 'SIGN_UP',
          type: 'SIGN_UP',
          value: 'Sign Up'
        }
      } else {
        this.selected = {
          key: 'NO_BUTTON',
          type: 'NO_BUTTON',
          value: 'No button'
        }
      }
    }
  },
  methods: {
    ...mapMutations('adsManager/adDesign', [
      'setStateField',
      'setStateFieldProp',
      'generateVariations',
      'removeError',
      'removeErrorAny',
      'changeHeadlineState',
      'changeCallActionState',
      'changeTargetLinkState',
      'changeDisplayLinkState'
    ]),
    ...mapActions('adsManager/adDesign', [
      'get_instant_forms'
    ]),
    ...mapActions('notifications', [
      'addNotification'
    ]),
    changeHeadline (event) {
      this.headline = event.value
      this.changeHeadlineState(this.headline)
      this.removeError({
        errorField: 'headline'
      })
    },
    changeTargetLink (event) {
      this.target_link = event.value
      if (this.target_link.length > 0) {
        this.display_link_check = this.$v.target_link.validity
        this.removeErrorAny({
          errorField: 'link_url'
        })
      }
      this.target_link_check = event.validity
    },
    changeDisplayLink (event) {
      this.display_link = event.value
      if (this.display_link.length > 0) {
        this.display_link_check = event.validity
        this.removeErrorAny({
          errorField: 'link_url'
        })
      }
    },
    toggleBrandAwarnessFields (event) {
      this.setStateField({
        field: 'brand_awareness_optional_fields_toggle',
        value: event.target.checked
      })
      this.setStateField({
        field: 'errors',
        value: []
      })
    },
    setSetting (arg) {
      this.removeError({
        errorField: 'call_to_actions'
      })
      this.selected = {
        key: arg.key,
        type: arg.key,
        value: arg.value
      }
    },
    addItem (arr, item, searchKey = null) {
      const data = { id: Date.now(), body: 'Duplicate value', type: 'danger' }
      if (typeof searchKey !== 'string') {
        if (arr.filter(v => v[searchKey[0]] === item[searchKey[0]] && v[searchKey[1]] === item[searchKey[1]]).length === 0) {
          arr.push(item)
        } else {
          this.addNotification(data)
        }
      } else if (searchKey) {
        if (arr.filter(v => v[searchKey] === item[searchKey]).length === 0) {
          arr.push(item)
        } else {
          this.addNotification(data)
        }
      } else {
        if (arr.filter(v => v === item).length === 0) {
          arr.push(item)
        } else {
          this.addNotification(data)
        }
      }
    },
    removeItem (arr, item, searchKey = null) {
      let ind
      if (searchKey) {
        ind = arr.indexOf(arr.find(v => v[searchKey] === item[searchKey]))
        arr.splice(ind, 1)
      } else {
        ind = arr.indexOf(arr.find(v => v === item))
        arr.splice(ind, 1)
      }
    },
    choose_destination (acc) {
      this.destination = acc
    }
  },
  mounted () {
    this.selected = this.getCallToActionSettings[0]
  }
})
