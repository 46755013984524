

















































































import Vue from 'vue'

import BaseCheckbox from '../../../components/BaseCheckbox.vue'
import TabSwitcher from '../../../components/TabSwitcher.vue'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'

import { mapMutations, mapGetters } from 'vuex'
import _ from 'lodash'

export default Vue.extend({
  name: 'Placements',
  components: {
    TabSwitcher,
    BaseCheckbox,
    BaseAlert
  },
  props: {
    adSet: {
      type: Object
    },
    adDesignData: {
      type: Object
    }
  },
  data () {
    return {
      agreement: false,
      testing: [],
      devices: [],
      platforms: [],
      clicks: 0,
      menu: [
        {
          id: 0,
          name: 'custom',
          title: 'Custom',
          description: ''
        },
        {
          id: 1,
          name: 'automatic',
          title: 'Automatic',
          description: ''
        }
      ]
    }
  },
  watch: {
    activePlacements () {
      this.removeError({ errorField: 'ad_placement' })
    }
  },
  computed: {
    ...mapGetters('adsManager/adDesign', [
      'getError',
      'getPlacementType',
      'getAdDesignData'
    ]),
    activePlacements () {
      const x = []
      Object.keys(this.adDesignData.ad_placement).forEach((item, index) => {
        if (Object.values(this.adDesignData.ad_placement)[index] === 'on') {
          x.push(item)
        }
      })
      return x
    },
    activeTab () {
      if (this.getPlacementType === 'automatic') {
        this.removeErrors()
      }
      return this.menu.find(menu => menu.name === this.getPlacementType)
    },
    type () {
      return this.getPlacementType === 'custom' ? 'Custom' : 'Automatic'
    }
  },
  methods: {
    ...mapMutations('adsManager/adDesign', [
      'setStateField',
      'setStateFieldProp',
      'togglePlacement',
      'removeError'
    ]),
    textTransform (text) {
      return _.capitalize(_.lowerCase(text))
    },
    removeErrors () {
      this.removeError({
        errorField: 'ad_placements.platforms'
      })
      this.removeError({
        errorField: 'ad_placements.positions'
      })
    },
    creativeFormat () {
      return this.adDesignData.creative_format === 'normal' ? 'image' : this.adDesignData.creative_format
    },
    allPlatformPositionChecker (platform) {
      return this.adSet.placements[this.creativeFormat()]['positions'][platform].every(element => {
        return this.getAdDesignData.new_ad_placement.positions.includes(element)
      })
    },
    togglePosition (event, platform, position) {
      const platforms = this.getAdDesignData.new_ad_placement.platforms
      const positions = this.getAdDesignData.new_ad_placement.positions
      if (event) {
        if (!platforms.find(v => v === platform)) {
          platforms.push(platform)
          this.removeErrors()
        }
        if (!positions.find(v => v === position)) {
          positions.push(position)
          this.removeErrors()
        }
      } else {
        const ind = positions.indexOf(position)
        positions.splice(ind, 1)
        if (
          this.adSet.placements[this.creativeFormat()]['positions'][platform]
            .filter(
              element => this.getAdDesignData.new_ad_placement.positions
                .includes(element)).length === 0) {
          const platformIndex = platforms.indexOf(platform)
          platforms.splice(platformIndex, 1)
        }
      }
    },
    toggleDevice (event, device) {
      const devices = this.getAdDesignData.new_ad_placement.devices
      if (event) {
        if (!devices.find(v => v === device)) {
          devices.push(device)
        }
      } else {
        const ind = devices.indexOf(device)
        devices.splice(ind, 1)
      }
      if (this.getAdDesignData.new_ad_placement.devices.length === 0) {
        this.setStateFieldProp({
          field: 'adDesignData',
          prop: 'new_ad_placement',
          value: {
            devices: [],
            platforms: [],
            positions: []
          }
        })
      }
    },
    selectAllPositions (event, platform) {
      this.removeErrors()
      this.adSet.placements[this.creativeFormat()]['positions'][platform]
        .forEach(v => {
          this.togglePosition(event, platform, v)
        })
    },
    deviceChecker (device) {
      return !!this.adDesignData.new_ad_placement.devices.find(v => v === device)
    },
    change_type (data) {
      this.setStateField({ field: 'placements_saving_type', value: data })
    },
    switchPlacement (acc) {
      this.change_type(acc.activeTab.name)
    }
  }
})
