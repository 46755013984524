









































































































































import Vue from 'vue'

import BasePagination from '../../../../components/BaseComponents/BasePagination/BasePagination.vue'
import InstantFormLeadMethodSwitch from './InstantFormLeadMethodSwitch.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import BaseInput from '@/components/BaseInputVuelidate.vue'
import CountryFlag from 'vue-country-flag'
import BaseAlert from '../../../../components/BaseComponents/BaseNotification/BaseAlert.vue'
import { numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import { messages } from '@/i18n/messages'

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default Vue.extend({
  name: 'InstantForm',
  components: {
    InstantFormLeadMethodSwitch,
    BaseInput,
    // VSelect,
    // CountryFlag,
    BasePagination,
    BaseAlert
  },
  data () {
    return {
      // lead_method: 'INSTANT_FORM',
      phone: '',
      selected: { code: '+1', key: 'US' },
      instant_form_ids: [],
      instant_form_ids_for_normal: [],
      forms: [
        { name: 'Untitled form 12/10/2020, 5:30', data: '22.10.2020', id: 1 },
        { name: 'Untitled form 12/10/2020, 5:30', data: '22.10.2020', id: 2 },
        { name: 'Untitled form 12/10/2020, 5:30', data: '22.10.2020', id: 3 }
      ],
      activePage: 1,
      searchQuery: '',
      destination: null
    }
  },
  validations: {
    phone: {
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    }
  },
  i18n: {
    messages
  },
  props: {
    isSwitch: {
      default: true
    },
    single: {
      default: false
    }
  },
  watch: {
    instant_form_ids (v) {
      this.setStateFieldProp({
        field: 'adDesignData',
        prop: 'lead_gen_form_ids',
        value: v
      })
    },
    searchQuery: _.debounce(function (v) {
      this.get_instant_forms({
        campaignId: this.$route.params.campaign_id,
        pageId: this.getFbPageById(this.adDesignData.page_id),
        page: this.activePage,
        search: v
      })
    }, 700)
  },
  computed: {
    ...mapState('adsManager/adDesign', ['adDesignServerData', 'adDesignData', 'lead_method']),
    ...mapGetters('adsManager/adDesign', ['getFbPageById', 'getError', 'getInstantFormByFbId'])
  },
  methods: {
    ...mapMutations('adsManager/adDesign', ['setStateField', 'setStateFieldProp', 'removeError']),
    ...mapActions('adsManager/adDesign', ['get_instant_forms', 'create_instant_form', 'get_all_instant_forms']),
    changePhone (event) {
      this.phone = event.value
      this.setStateFieldProp({
        field: 'adDesignData',
        prop: 'phones',
        value: {
          tel: event.value
        }
      })
      this.removeError({ errorField: 'phones' })
    },
    addInstantFormId (event) {
      event.target.checked
        ? this.instant_form_ids.push(event.target.value)
        : this.instant_form_ids.splice(this.instant_form_ids.indexOf(event.target.value), 1)
    },
    chooseInstantForm (event) {
      this.instant_form_ids_for_normal = event.target.value
      this.$emit('change-instant-form', this.instant_form_ids_for_normal)
    },

    openWindow () {
      localStorage.setItem('process_creating_instant_form', JSON.stringify(
        {
          campaignId: this.$route.params.campaign_id,
          status: 'start'
        }))
      this.repeatRequest()
      this.create_instant_form({
        campaignId: this.$route.params.campaign_id,
        adAccountId: this.$store.state.adsManager.campaign.account_id,
        pageId: this.getFbPageById(this.adDesignData.page_id)
      }).then((response) => {
        const doc = response.data.url
        window.open(doc, 'action', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=600,left=-1000,top=-1000')
      })
    },
    closeWindow () {
      window.close()
    },
    repeatRequest () {
      const status = JSON.parse(localStorage.getItem('process_creating_instant_form')).status
      if (status === 'start') {
        setTimeout(() => {
          this.get_instant_forms({
            campaignId: this.$route.params.campaign_id,
            pageId: this.getFbPageById(this.adDesignData.page_id),
            page: this.activePage
          })
            .then(res => {
              if (status === 'start') {
                this.repeatRequest()
              }
            })
        }, 3000)
      }
    },
    setLeadMethod (acc) {
      this.setStateField({ field: 'lead_method', value: acc })
    },
    switchInstantFormPage (event) {
      this.get_instant_forms({
        campaignId: this.$route.params.campaign_id,
        pageId: this.getFbPageById(this.adDesignData.page_id),
        page: event
      })
    }
  }
})
