











































import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'InstantFormLeadMethodSwitch',
  computed: {
    ...mapState('adsManager/adDesign', ['lead_method'])
  },
  methods: {
    setLeadMethod (leadMethod) {
      this.$emit('set-lead-method', leadMethod)
    }
  }
})
